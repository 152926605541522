#nprogress .bar {
  background: #aaaef5 !important;
  height: 3px;
  z-index: 10000;
}

#nprogress .peg {
  box-shadow: 0 0 0.8rem #aaaef5, 0 0 5px #aaaef5 !important;
}

.h-2-black-bold {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin-left: 25px;
}

.administratorTabContainer {
  margin-bottom: 0;
}

.h-2-sub-title {
  color: #000000;	
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;	
  font-size: 16px;	
  line-height: 22px;
  margin-left: 25px;
}

.addButton {  
  color: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  float: right;
  background-color: #0033AB;
  margin-right: 30px;
  height: 34px;    
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}
.changeButton {  
  color: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  float: right;
  background-color: #0033AB;
  margin-right: 3px;
  height: 34px;
  min-width: 84px;
  max-width: 150px;
  position: unset; 
  border-radius: 3px;
  border: none;
  outline: none;
  top: 200px;
  cursor: pointer;
}
.mainDiv{
  margin-top:30px
}

.GroupsListHeader {
  height: 35px;
  list-style-type: none;
  border: 1px solid #F0F0F0;
  background-color: #E8E8E8;
  width: 100%;
  color: black;
  display: flex;
}

.HeaderIconColumn{
  padding: 8.5px;
  width: 35px;
  height: 35px;
  float: left;
  border-left: 1px solid #F0F0F0;
  font-size: 12px;
  text-align: center;
  line-height: 100%;
  
}
.HeaderIconColumn_edit {
  padding: 8.5px;
  width: 35px;
  height: 35px;
  float: left;
  border-left: 1px solid #F0F0F0;
  font-size: 12px;
  text-align: center;
  line-height: 100%;
}

.ClickableColumn {
  padding: 8.5px;
  width: 35px;
  height: 35px;
  float: left;
  border-left: 1px solid #F0F0F0;
  font-size: 12px;
  text-align: center;
  line-height: 100%;
}

.ClickableColumn:hover {
  padding: 6.5px 5px 5px 5px;
}

.GroupsTableHeaderIcon {
  height: 16px;
}

.GroupEdit {
  height: 16px;
  cursor: pointer;
}

.EditAppContainer {
  display: flex;
  height: 40%;
}

.EditAppIconContainer {
  display: flex;
  width: 20%;
  flex-direction: row-reverse;
  margin: 8px;
}

.AppEditIcon {
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px;
}

.FinalNodeIcon {
  float: left;
  height: 35px;
  padding: 8.5px;
  
}

.ArrowIcon {
  float: left;
  height: 35px;
  padding: 11px;
  cursor: pointer;
}

.ArrowRightIcon {
  height: 10px;
  margin: 8px;
  cursor: pointer;
}

.NotOpenedArrayContainer {
  width: 26px;
  height: 26px;
  background-color: #0033AB;
  border-radius:5px;
  margin: 4.5px;
  float: left;
}

.ExpendedHeaderColumn {
  width: calc(100% - 140px);
  line-height: 35px;
  padding-left: 9px;
  float: left;
}
.ExpendedCell {
  width: calc(100% - 175px);
  line-height: 35px;
  padding-left: 9px;
  float: left;    
  color: #0033AB;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ExpendedCell:hover, .ArrowIcon:hover {  
  cursor: pointer;
}

.Groupli {
  list-style-type: none;
  width: 100%;
}

.Group {
  height:35px;
  overflow:visible;
  border-left: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  background-color: #F7F7F7;
  width: 100%;
}

.Group:hover {
  background-color: #FFFFFF;  
}

#responsive-dialog-title h6 {
  font-size: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color:#000;
  background-color: #F7F7F7;
}

#responsive-dialog-title {
  padding: 14px 24px 4px;
  background-color: #F7F7F7;
}

#dialogbutton {
  background: #fff;
  padding: 0px 0px 8px 22px;
 justify-content: flex-start;
}

#dialogbutton button:disabled,
#dialogbutton button[disabled]{
  opacity: 0.5;
}

#dialogbutton Button:hover {
  background-color: #0033AB;
  color:#fff
}

#dialogbutton Button {  
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-transform: lowercase;
  text-align: center;
  border: 1px solid #0033AB;
  cursor: pointer;
}

#dialogbutton span {
  text-transform: capitalize !important;
}

#addbg{
  background: #fff
}

.AppsListContainer {
  padding: 12px;
}

.MuiSnackbarContent-message {
  display: flex;
}

.MuiSnackbarContent-action {
  height: 90%;
}

.MuiSelect-select[aria-pressed='true'] ~ svg{
  transform: rotate(180deg);
}

.MuiButton-label {
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .HeaderIconColumn {
        display: none !important;
    }
    .ExpendedCell {
        width: calc(100% - 70px) !important;
    }
    .ExpendedHeaderColumn {
        width: calc(100% - 36px) !important;
    }
}